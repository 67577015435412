import "./style.css";
import '@mantine/core/styles.css';
import { MantineProvider } from "@mantine/core";
import Form from "./Form";

function App() {
  return (
    <div className="App">
      <div>
        <MantineProvider >
          <Form />
        </MantineProvider>
      </div>
    </div>
  );
}

export default App;
