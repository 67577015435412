import React, { useState } from "react";
import "./style.css";
import logo from "./wave.png";
import axios from "axios";

import {
  Card,
  Container,
  Input,
  Button,
  Select,
  Grid,
  // Collapse,
  // Text,
} from "@mantine/core";
// import { useDisclosure } from "@mantine/hooks";
import { IconVolume, IconCodePlus } from "@tabler/icons-react";
import OpenAI from "openai";

const voices = {
  "Ivy [English - American]": "Ivy",
  "Joanna [English - American]": "Joanna",
  "Joey [English - American]": "Joey",
  "Justin [English - American]": "Justin",
  "Kendra [English - American]": "Kendra",
  "Kimberly [English - American]": "Kimberly",
  "Salli [English - American]": "Salli",
  "Nicole [English - Australian]": "Nicole",
  "Russell [English - Australian]": "Russell",
  "Emma [English - British]": "Emma",
  "Brian [English - British]": "Brian",
  "Amy [English - British]": "Amy",
  "Raveena [English - Indian]": "Raveena",
  "Geraint [English - Welsh]": "Geraint",
  "Ricardo [Brazilian Portuguese]": "Ricardo",
  "Vitoria [Brazilian Portuguese]": "Vitoria",
  "Lotte [Dutch]": "Lotte",
  "Ruben [Dutch]": "Ruben",
  "Mathieu [French]": "Mathieu",
  "Celine [French]": "Celine",
  "Chantal [Canadian French]": "Chantal",
  "Marlene [German]": "Marlene",
  "Dora [Icelandic]": "Dora",
  "Karl [Icelandic]": "Karl",
  "Carla [Italian]": "Carla",
  "Giorgio [Italian]": "Giorgio",
  "Mizuki [Japanese]": "Mizuki",
  "Liv [Norwegian]": "Liv",
  "Maja [Polish]": "Maja",
  "Jan [Polish]": "Jan",
  "Ewa [Polish]": "Ewa",
  "Cristiano [Portuquese]": "Cristiano",
  "Ines [Portuquese]": "Ines",
  "Carmen [Romanian]": "Carmen",
  "Maxim [Russian]": "Maxim",
  "Tatyana [Russian]": "Tatyana",
  "Enrique [Spanish]": "Enrique",
  "Penelope [US Spanish]": "Penelope",
  "Miguel [US Spanish]": "Miguel",
  "Conchita [Castilian Spanish]": "Conchita",
  "Astrid [Swedish]": "Astrid",
  "Filiz [Turkish]": "Filiz",
  "Gwyneth [Welsh]": "Gwyneth",
};

const options = Object.keys(voices);
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const POLLY_ENDPOINT = process.env.REACT_APP_POLLY_ENDPOINT;
const openai = new OpenAI({ apiKey: apiKey, dangerouslyAllowBrowser: true });

function Form() {
  const [char, setChar] = useState("");
  const [ipa, setIPA] = useState("");
  const [name, setName] = useState("");
  const [voice, setVoice] = useState("");
  const [embedding, setEmbedding] = useState();

  const nameToIPA = async (name) => {
    if (name === "") {
      alert("Please enter a name");
      return;
    }

    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            'Give me the IPA of the name "' +
            name +
            "\" based on common English pronunciation patterns. Don't respond with anything else.",
          // content: "Give me the IPA of " + name + " based on common " + language + " pronunciation patterns. Don't respond with anything else.",
        },
      ],
      model: "gpt-3.5-turbo",
    });
    const ipa = completion.choices[0].message.content;
    setIPA(ipa.replace(/[/ˈ:]+/g, ""));
  };

  const voiceChange = (value) => {
    setVoice(value);
  };

  const charChange = (value) => {
    setChar(value);
  };

  const handleIPAChange = (event) => {
    setIPA(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const insert = () => {
    setIPA((prevText) => prevText + char);
    setChar("");
  };

  const sendToPolly = async (ipa, voice) => {
    if (ipa === "" || voice === "") {
      alert("Please enter a name and select a voice");
      return;
    }

    const data = JSON.stringify({
      text: ipa,
      voice: voice,
    });

    try {
      const response = await axios.post(POLLY_ENDPOINT, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const audio_data = "data:audio/mpeg;base64," + response.data;
      const audio = new Audio(audio_data);
      audio.play();
      setEmbedding(buildPlugin(ipa, audio_data));
    } catch (error) {
      console.log(error);
    }
  };

  function buildPlugin(ipa, audioData) {
    var control = `\`<div>(${ipa}) <button style="all: unset;cursor: pointer;" onclick="document.getElementById('tpp-audio').play()">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0"  width="20" height="20" viewBox="0 0 75 75">
        <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke:#111;stroke-width:5;stroke-linejoin:round;fill:#111;"/>
        <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke:#111;stroke-width:5;stroke-linecap:round"/>
    </svg>
</button></div>\``;
    var plugin = `<span class="audio"></span>
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
<script>$('.audio').html(${control});$('.audio').prepend('<audio id="tpp-audio"><source src="${audioData.replace(
      /(\r\n|\n|\r)/gm,
      ""
    )}" type="audio/mpeg"></source></audio>');</script>`;
    return plugin;
  }

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <div
          style={{
            textAlign: "center",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
        >
          <img src={logo} className="header-img" alt="logo" />
          <h1
            style={{ fontWeight: "600", fontFamily: "Italiana" }}
            className="header-logo"
          >
            The Pronunciation Project
          </h1>
        </div>
        <Card p={40} className="card-show">
          <Card.Section>
            <Input.Wrapper label="Your name" size="md">
              <Input
                onChange={handleNameChange}
                size="md"
                variant="unstyled"
                className="inputbar"
                placeholder="John Doe"
              />
            </Input.Wrapper>

            <Button
              variant="filled"
              size="md"
              fullWidth
              my="lg"
              color="dark"
              onClick={() => nameToIPA(name)}
            >
              Generate IPA
            </Button>

            <Input.Wrapper label="Your IPA" size="md">
              <Input
                value={ipa}
                onChange={handleIPAChange}
                size="md"
                variant="unstyled"
                className="inputbar"
                placeholder="dʒɑn doʊ"
              />
            </Input.Wrapper>

            <Grid mt="xl" mb="xs" align="end">
              <Grid.Col span={6}>
                <Select
                  label="IPA Characters"
                  data={[
                    { group: "A", items: ["ɑ", "æ", "ɐ"] },
                    { group: "B", items: ["β"] },
                    { group: "C", items: ["k", "tʃ"] },
                    { group: "D", items: ["d", "ð"] },
                    { group: "E", items: ["ə", "ɛ"] },
                    { group: "I", items: ["ɪ"] },
                    { group: "N", items: ["ŋ"] },
                    { group: "O", items: ["ɔ"] },
                    { group: "R", items: ["ɹ"] },
                    { group: "S", items: ["ʃ"] },
                    { group: "T", items: ["θ"] },
                    { group: "U", items: ["ʊ"] },
                    { group: "Y", items: ["ʎ"] },
                    { group: "Z", items: ["ʒ"] },
                    {
                      group: "Other Characters",
                      items: [
                        "ɟ",
                        "ɳ",
                        "ɲ",
                        "ɱ",
                        "ʔ",
                        "ⱱ",
                        "ɾ",
                        "ɽ",
                        "ɸ",
                        "ʂ",
                        "ʐ",
                        "ç",
                        "ʝ",
                        "ɣ",
                        "χ",
                        "ħ",
                        "ɮ",
                        "ɬ",
                        "ɦ",
                        "ʕ",
                        "ɻ",
                        "ɰ",
                        "ɭ",
                        "œ",
                        "ɘ",
                        "ɵ",
                        "ø",
                      ],
                    },
                    { group: "Stresses", items: ["ˈ", "ˌ", "ː", "ˑ"] },
                  ]}
                  onChange={charChange}
                  value={""}
                  size="md"
                  className="inputbar"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  color="dark"
                  variant="filled"
                  size="md"
                  fullWidth
                  onClick={insert}
                >
                  {" "}
                  Insert{" "}
                </Button>
              </Grid.Col>
            </Grid>

            <Select
              onChange={voiceChange}
              value={voice}
              label="Voice"
              placeholder="Pick voice"
              data={options}
              className="inputbar"
            />

            <Grid mt="xl" mb="xs">
              <Grid.Col span={6}>
                <Button
                  onClick={() => sendToPolly(ipa, voices[voice])}
                  color="dark"
                  variant="filled"
                  size="md"
                  fullWidth
                  leftSection={<IconVolume size={14} />}
                >
                  Pronounce
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  onClick={() => navigator.clipboard.writeText(embedding)}
                  color="dark"
                  variant="filled"
                  size="md"
                  fullWidth
                  leftSection={<IconCodePlus size={14} />}
                >
                  Copy Embedding
                </Button>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </div>
    </Container>
  );
}

export default Form;
